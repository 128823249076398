<template>
  <lf-card class="py-6">
    <loader :is-loading="isSubmitting" />
    <lf-h3 class="mb-3">{{ $t("DEALS.EXPERIAN.SUBCODES") }}</lf-h3>
    <access-control :roles="[ADMIN, SUPER_ADMIN]">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-6 border">
          <div
            class="row-span-2 flex items-end bg-primary text-white font-semibold text-xs p-2"
          >
            {{ $t("DEALS.EXPERIAN.INQUIRY_TYPE") }}
          </div>
          <div
            class="bg-primary text-white font-semibold text-center p-2 col-span-5"
          >
            {{ $t("DEALS.EXPERIAN.CONSUMER_SCORE_MODEL") }}
          </div>
          <div
            v-for="(value, subcode) in scoreModelKeys"
            :key="subcode"
            class="bg-primary text-white font-semibold text-xs text-center p-2"
          >
            {{ startCase(subcode) }}
          </div>
          <template v-for="(field, key, index) in subcodeFields" :key="key">
            <div
              class="flex items-center text-headline font-semibold text-xs p-2"
              :class="{ 'bg-blue-100': index % 2 === 0 }"
            >
              {{ startCase(key).replace("Experian Bop", "") }}
            </div>
            <template v-for="(_, subcode) in field" :key="`${key}-${subcode}`">
              <div
                class="flex items-center justify-stretch p-2"
                :class="{ 'bg-blue-100': index % 2 === 0 }"
              >
                <lf-input
                  :value="subcodeFields[key][subcode]"
                  class="w-full h-9"
                  :name="`${key}-${subcode}`"
                  no-margin
                />
              </div>
            </template>
          </template>
        </div>
        <div class="flex justify-end items-center min-w-full pt-6">
          <primary-button
            data-cy="subcodes-submit"
            type="submit"
            :disabled="isSubmitting"
          >
            {{ $t("COMMON.SAVE_CHANGES") }}
          </primary-button>
        </div>
      </form>
    </access-control>
  </lf-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { SUPER_ADMIN, ADMIN } from "@/helpers/constants";
import startCase from "lodash/startCase";
import { PERSONAL_CREDIT_SERVICES } from "@/helpers/constants/underwriting";
import { BOP_CONSUMER_SCORE_MODELS } from "@/helpers/constants/experian";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";

import type { BusinessCreditService } from "@/models/options";
import type { IClient } from "@/models/clients";

const { getters, dispatch } = useStore();
const route = useRoute();

const client = computed<IClient>(() => ({
  ...getters[
    route.name === "ClientOrganization"
      ? "auth/authClientSettings"
      : "clients/active"
  ]
}));

const scoreModelKeys = computed(() =>
  (Object.keys(BOP_CONSUMER_SCORE_MODELS) as BusinessCreditService[]).reduce(
    (prev, curr) => {
      return { ...prev, [curr]: "" };
    },
    {}
  )
);

const subcodeFields = computed(() => {
  const currentSubcodes = client.value.experian_subcodes;
  return PERSONAL_CREDIT_SERVICES.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: {
        // merge any existing values with the empty set
        // have to do it this way since existing values doesn't return empty keys
        ...scoreModelKeys.value,
        ...currentSubcodes?.[curr]
      }
    };
  }, {});
});

const { handleSubmit, isSubmitting } = useForm({});

const onSubmit = handleSubmit(async (values, actions) => {
  values = preparePayload(values);
  const action = "clients/updateClientExperianSubcodes";
  await dispatchAction(
    {
      clientId: client.value.id,
      experianCredentials: values
    },
    actions,
    action
  );
  await dispatch("clients/find", client.value.id);
});

const preparePayload = (values: Record<string, string>) => {
  const payload = Object.keys(values).reduce(
    (prev, curr) => {
      const [service, subcode] = curr.split("-");
      if (values[curr]) {
        return {
          ...prev,
          [service]: {
            ...prev[service],
            [subcode]: values[curr]
          }
        };
      }
      return prev;
    },
    {} as Record<string, Record<string, string>>
  );
  return { experian_subcodes: payload };
};
</script>
